import { template as template_6bcd459aea7049a9980cfbdcfa6b91a2 } from "@ember/template-compiler";
const FKLabel = template_6bcd459aea7049a9980cfbdcfa6b91a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
