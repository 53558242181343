import { template as template_4e759e9f3e994a1f8dc5f9e593b64f8a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4e759e9f3e994a1f8dc5f9e593b64f8a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
