import { template as template_d2e77a66c6dc40f2841ccdaaeb6c7c61 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d2e77a66c6dc40f2841ccdaaeb6c7c61(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
